<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('staff.staff_shift') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('staff.staff_shift') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="box">
        <div class="box-header small-box-header">
          <h4 class="box-title mb-0">Shift Info</h4>
        </div>
        <div class="box-body p-1" v-if="shift">
          <table class="table table-small table-borderless">
            <tbody>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Shift ID
              </td>
              <td>: {{ shift.shift_id }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Department
              </td>
              <td>: {{ shift.department.name }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Date
              </td>
              <td>: {{ shift.start + ' - ' + shift.end }}</td>
            </tr>
            <tr>
              <td>
                <span style="color: rgb(81, 86, 190);">◆</span> Status
              </td>
              <td>
                :
                <shift-status :status="shift.status" />
              </td>
            </tr>
            </tbody>

          </table>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="box">
        <div class="box-header small-box-header text-end">
          <h4 class="box-title mb-0">
            <!--            <button class="btn btn-sm btn-success me-1">Save</button>-->
            <button class="btn btn-sm btn-primary"
                    v-if="shift && shift.status === 0"
                    @click="makeLive">Live</button>
          </h4>
        </div>
        <div class="box-body p-0" v-if="shift">
          <table class="table table-bordered table-small">
            <thead>
            <tr>
              <th style="width: 25%">
                <input type="text"
                       v-model="search"
                       class="form-control form-control-sm"
                       placeholder="Search">
              </th>
              <th class="text-center"
                  style="width: 10%"
                  v-for="(date, i) in dates"
                  :key="'date_'+i">
                <a href="#"
                   v-if="calendar.hasPrevious && i === 0"
                   @click.prevent="changeDates(week - 1)"
                   class="me-1">
                  <i class="fas fa-chevron-left"></i>
                </a>

                {{ date.weekday }}
                <a href="#"
                   v-if="calendar.hasNext && i === 6"
                   @click.prevent="changeDates(week + 1)"
                   class="ms-1">
                  <i class="fas fa-chevron-right"></i>
                </a>
                <br>

                <span class="text-muted">{{ date.day }}</span>
              </th>
              <th v-for="i in 7 - dates.length"
                  style="width: 10%"
                  :key="'blank_'+i"></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="staff in shift.staffs" :key="'staff_'+staff.id">
              <tr v-if="!search.length || staff.user.full_name.toLowerCase().includes(search.toLowerCase())">
                <td>
                  <div class="d-flex align-items-center">
                    <div class="me-15">
                      <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                           onerror="this.src='/images/avatar-girl.png'"
                           class="avatar avatar-lg rounded10">
                    </div>
                    <div class="d-flex flex-column flex-grow-1 fw-500">
                      <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ staff.user.full_name }}</a>
                      <span class="text-fade">{{ staff.staff_id }}</span>
                    </div>
                  </div>
                </td>
                <td v-for="(date, i) in dates"
                    :key="'date_'+i">
                  <div :class="['w-p100 rounded h-p100 p-1', getScheduleClass(staff.id, date.raw)]">
                    <div class="row">
                      <div class="col-12 text-end">
                        <a href="#"
                           @click.prevent="$refs.editScheduleModal.show($route.params.id, date.raw, staff.id, getSchedule(staff.id, date.raw))"
                           class="text-muted">
                          <i class="fas fa-edit"></i>
                        </a>
                      </div>
                    </div>
                    <p class="text-center text-dark text-bold m-0 fs-12"
                       v-html="getTime(staff.id, date.raw)"></p>
                    <p class="text-center text-muted m-0 fs-12"
                       v-html="getStatus(staff.id, date.raw)"></p>
                  </div>
                </td>
                <td v-for="i in 7 - dates.length"
                    style="width: 10%"
                    :key="'blank_'+i"></td>
              </tr>
            </template>

            </tbody>
            <tfoot>
            <tr>
              <td colspan="8">
                <a href="#"
                   @click.prevent="$refs.addNewEmployeeModal.show($route.params.id)"
                   class="text-bold text-info">+ Add new employee</a>
              </td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>

  <add-new-employee-modal
    @added="loadShift"
    ref="addNewEmployeeModal" />

  <edit-schedule-modal
    @added="getDates"
    ref="editScheduleModal" />
</template>

<script>
import AddNewEmployeeModal from "@/views/staff/shift/edit/AddNewEmployeeModal.vue";
import ShiftStatus from "@/views/staff/shift/ShiftStatus.vue";
import EditScheduleModal from "@/views/staff/shift/edit/EditScheduleModal.vue";
export default {
  components: {AddNewEmployeeModal, ShiftStatus, EditScheduleModal},
  data() {
    return {
      shift: null,
      week: 0,
      search: '',
      calendar: {
        hasPrevious: false,
        hasNext: false
      },
      dates: [],
      schedules: [],
    }
  },
  created() {
    this.loadShift();
    this.getDates();
  },
  methods: {
    getScheduleClass(staffId, date) {
      let schedule = this.getSchedule(staffId, date);

      if (schedule) {
        if (schedule.status === 1)
          return 'bg-success-light';
        else if (schedule.status === 2)
          return 'bg-info-light';
        else if (schedule.status === 3)
          return 'bg-danger-light'
        else
          return '';
      } else
        return '';
    },
    getSchedule(staffId, date) {
      let schedule = this.schedules.find(s => s.staff_id === staffId && s.date === date);

      return schedule;
    },
    getTime(staffId, date) {
      let schedule = this.getSchedule(staffId, date);

      if (schedule && schedule.status !== 3)
        return schedule.start + ' - ' + schedule.end;
      else
        return '&nbsp;';
    },
    getStatus(staffId, date) {
      let schedule = this.schedules.find(s => s.staff_id === staffId && s.date === date);

      if (schedule) {
        if (schedule.status === 1)
          return 'Full Day';
        else if (schedule.status === 2)
          return 'Half Day';
        else if (schedule.status === 3)
          return 'Annual Leave'
        else
          return '';
      } else
        return '&nbsp;';
    },
    changeDates(week) {
      this.week = week;
      this.getDates();
    },
    getDates() {
      this.dates = [];

      this.axios.get('/shifts/dates?week=' + this.week + '&shift=' + this.$route.params.id)
        .then(res => {
          this.dates = res.data.data.dates;
          this.calendar.hasPrevious = res.data.data.has_previous;
          this.calendar.hasNext = res.data.data.has_next;
          this.schedules = res.data.data.schedules;
        })
    },
    loadShift() {
      this.shift = null;

      this.axios.get('/shifts/' + this.$route.params.id)
        .then(res => {
          this.shift = res.data.data;
        })
    },
    makeLive() {
      this.$swal({
        title: "Live",
        text: "Are you sure want to live?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: "No",
        confirmButtonText: "Yes",
        // confirmButtonColor: '#88dd39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.post('/shifts/live/' + this.$route.params.id)
            .then(res => {
              this.shift = res.data.data;
            });
        }
      });
    }
  }
}
</script>