<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('staff.staff') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('staff.assign_staff_to_case') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-10">
              <div class="input-group">
                <input type="text"
                       class="form-control"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ Case No. หรือ Visit No. หรือ ชื่อลูกค้า">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('staff.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="cases"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:patient_full_name="{ row }">
              <img :src="row.patient.image ? row.patient.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="rounded h-30 w-30">
              {{ row.patient.full_name }}
            </template>

            <template v-slot:staffs="{ row }">
              <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                   onerror="this.src='/images/avatar-girl.png'"
                   class="h-30 rounded me-2"
                   v-for="staff in row.staffs"
                   :key="'staff_'+staff.id">
            </template>

            <template v-slot:action="{ row }">
              <router-link :to="{name: 'staff_assign_details', params: {id: row.id}}"
                           class="text-muted">
                <i class="fas fa-edit"></i> {{ $t('staff.manage') }}
              </router-link>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-staff-modal ref="addNewStaffModal" @added="loadData" />
</template>

<script>
import AddNewStaffModal from "@/views/staff/modals/AddNewStaffModal";
import TableComponent from "@/components/TableComponent";

export default {
  components: {TableComponent, AddNewStaffModal},
  data() {
    return {
      cases: [],
      pagination: null,
      queryParams: {
        status: '',
        department_id: '',
        page: 1,
        search: '',
        sort: 'case_no',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'case_no',
        sort: 'desc'
      },
      columns: [
        {
          label: this.$t('staff.case_no'),
          field: "case_no",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('staff.visit_no'),
          field: 'visit.visit_no',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('staff.hn'),
          field: 'patient.hn',
          sortable: false,
          searchable: false,
          display(row) {
            return row.patient.hn
          }
        },
        {
          label:  this.$t('staff.customer'),
          field: 'patient.full_name',
          sortable: false,
          searchable: true,
          slot: true
        },
        {
          label:  this.$t('staff.staff'),
          field: 'staffs',
          sortable: false,
          searchable: false,
          slot: true
        },
        {
          label: this.$t('staff.date'),
          field: 'doctorSlot.date',
          sortable: true,
          searchable: true
        },
        {
          label: this.$t('staff.time'),
          field: 'doctorSlot.time_format',
          sortable: false,
          searchable: false
        },
        {
          label: this.$t('staff.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: this.$t('staff.action'),
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.cases = [];

      this.axios.get('/visit-cases', {params: this.queryParams})
        .then((response) => {
          this.cases = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>