<template>
  <div v-if="caseObj">
    <div class="row">
      <div class="col-3">
        <h4>Manage Staff : {{ caseObj.case_no }}</h4>
      </div>
      <div class="col-9 text-end">
        <select v-if="editingStatus"
                v-model="statusForm.status"
                class="form-select form-select-sm d-inline w-auto me-2">
          <option value="1">Waiting</option>
          <option value="2">Ready</option>
        </select>

        <assign-status class="me-2" :status="caseObj.staff_status" v-else />

        <button class="btn btn-sm btn-secondary me-20"
                v-if="!editingStatus"
                @click="editStatus">
          <i class="fas fa-edit"></i>
        </button>

        <button class="btn btn-sm btn-success me-20"
                v-else
                @click="saveStatus">
          <i class="fas fa-save"></i>
        </button>

        <button class="btn btn-primary btn-sm me-2"
                @click="$refs.selectStaffModal.show()">เพิ่มพนักงาน</button>
        <button class="btn btn-success btn-sm" @click="submit">เสร็จสิ้น</button>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-3">
        <div class="box">
          <div class="box-header small-box-header">
            <h4 class="box-title">Assigned Doctor</h4>
          </div>
          <div class="box-body p-3">
            <div class="d-flex align-items-center">
              <div class="me-15">
                <img :src="caseObj.doctor.image ? caseObj.doctor.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="avatar avatar-lg rounded10 bg-primary-light" />
              </div>
              <div class="d-flex flex-column flex-grow-1 fw-500">
                <a href="#" class="text-dark hover-primary mb-1 fs-16">{{ caseObj.doctor.full_name }}</a>
                <span class="text-fade">{{ caseObj.doctor.department.name }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="box mb-0">
          <div class="box-header small-box-header">
            <h4 class="box-title">ข้อมูลผู้รับบริการ</h4>
          </div>
          <div class="box-body p-3">
            <div class="row">
              <div class="col-8">
                <table class="patient-details-table table table-borderless table-sm">
                  <tbody>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> HN
                    </td>
                    <td>: {{ caseObj.patient.hn }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> รหัสลูกค้า
                    </td>
                    <td>: {{ caseObj.patient.patient_id }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> ชื่อลูกค้า
                    </td>
                    <td>: {{ caseObj.patient.full_name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> ชื่อเล่น
                    </td>
                    <td>: {{ caseObj.patient.nick_name }}</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> อายุ
                    </td>
                    <td>: 27 ปี</td>
                  </tr>
                  <tr>
                    <td>
                      <span style="color:#5156BE;">◆</span> หมู่เลือด
                    </td>
                    <td>: {{ caseObj.patient.bloodGroup.name }}</td>
                  </tr>
                  </tbody>
                </table>

                <hr>

                <span style="color:#5156BE;">◆</span> ข้อมูลบริการ

                <div class="ps-3">
                  <template v-for="(service, i) in caseObj.services"
                            :key="'service_'+service.id">
                    <span>{{ i+1 }}. {{ service.name_th }}</span> <br>
                  </template>
                </div>
              </div>
              <div class="col-4">
                <img :src="caseObj.patient.image ? caseObj.patient.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'"
                     class="w-p100">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-9">
        <staff-list :staffs="staffs"
                    @removeStaff="removeStaff"
                    :selectedStaffs="selectedStaffs"
                    v-if="selectedStaffs.length" />
        <no-staff-component v-else />
      </div>
    </div>
  </div>

  <select-staff-modal @addStaff="addStaff"
                      :staffs="staffs"
                      ref="selectStaffModal" />
</template>

<script>
import NoStaffComponent from './components/NoStaffComponent'
import StaffList from './components/StaffList'
import SelectStaffModal from './components/SelectStaffModal'
import AssignStatus from "@/views/staff/assign_case/AssignStatus.vue";

export default {
  components: {AssignStatus, NoStaffComponent, StaffList, SelectStaffModal},
  data() {
    return {
      caseObj: null,
      staffs: [],
      selectedStaffs: [],
      editingStatus: false,
      statusForm: {
        status: ''
      },
    }
  },
  created() {
    this.loadCase();
    this.loadStaffs();
  },
  methods: {
    editStatus() {
      this.statusForm.status = this.caseObj.staff_status;
      this.editingStatus = true;
    },
    saveStatus() {
      this.statusForm.visit_case_id = this.caseObj.id;

      this.axios.post('/visit-cases/staff-status', this.statusForm)
        .then(() => {
          this.caseObj.staff_status = this.statusForm.status;
          this.editingStatus = false;
        })
    },
    loadCase() {
      this.caseObj = null;

      this.axios.get('/visit-cases/' + this.$route.params.id)
        .then(res => {
          this.caseObj = res.data.data;
          this.selectedStaffs = this.caseObj.staffs.map(s => s.id);
        })
    },
    loadStaffs() {
      this.staffs = [];

      this.axios.get('/staffs')
        .then(res => {
          this.staffs = res.data.data;
        })
    },
    addStaff(staffId) {
      if (staffId !== '' && !this.selectedStaffs.includes(staffId))
        this.selectedStaffs.push(staffId);
    },
    removeStaff(staffId) {
      this.selectedStaffs = this.selectedStaffs.filter(s => s !== staffId);
    },
    submit() {
      this.loading = true;

      this.axios.patch('/visit-cases/' + this.$route.params.id, {
          staff_ids: this.selectedStaffs
        })
        .then(() => {
          this.$router.push({name: 'staff_assign'})
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>