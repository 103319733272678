<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-new-staff-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            ลงเวลาวันทำงานของพนักงาน
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-3">
              <img :src="userImage">
            </div>
            <div class="col-md-9">
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('note')}]">
                <label class="col-sm-2 col-form-label">พนักงาน</label>
                <div :class="['col-sm-10 form-group mb-2', {'error': errorFor('user_id')}]">
                  <select class="form-control form-control-sm select2"
                          id="user-select"
                          style="width: 100%;">
                    <option value="">เลือกพนักงาน</option>
                    <option :value="user.id"
                            v-for="user in users"
                            :key="'user_'+user.id">{{ user.full_name }}</option>
                  </select>

                  <v-errors :errors="errorFor('user_id')"></v-errors>
                </div>
              </div>
              <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('note')}]">
                <label class="col-sm-2 col-form-label">ตำแหน่ง</label>
                <div class="col-sm-10">
                  <select class="form-select form-select-sm"
                          v-model="form.type">
                    <option value="full">พนักงานประจำ</option>
                    <option value="part">พนักงานพาร์ทไทม์</option>
                  </select>
                </div>
              </div>

<!--              <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-10">
                  <div class="box">
                    <div class="box-body p-3 bg-secondary-light">
                      <h5 class="text-bold">ระบุวันหยุด</h5>

                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label">รูปแบบวันหยุด</label>
                        <div class="col-sm-8">
                          <div class="c-inputs-stacked">
                            <input name="group123e" type="radio" id="radio_1233" value="1" checked="">
                            <label for="radio_1233" class="me-10">เต็มวัน</label>

                            <input name="group123e" type="radio" id="radio_4563" value="0">
                            <label for="radio_4563" class="me-10">ครึ่งวัน (เช้า)</label>

                            <input name="group123e" type="radio" id="radio_456333" value="0">
                            <label for="radio_456333" class="me-10">ครึ่งวัน (บ่าย)</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label">จำนวนวันหยุด</label>
                        <div class="col-sm-8">
                          <select class="form-select form-select-sm">
                            <option value="">2 วัน</option>
                          </select>
                        </div>
                      </div>
                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label"></label>
                        <div class="col-sm-8">
                          <div class="input-group mb-3">
                            <span class="input-group-text">วันหยุด (1)</span>
                            <input type="date" class="form-control">
                          </div>
                        </div>
                      </div>
                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label"></label>
                        <div class="col-sm-8">
                          <div class="input-group mb-3">
                            <span class="input-group-text">วันหยุด (2)</span>
                            <input type="date" class="form-control">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <div class="box-body p-3 bg-secondary-light">
                      <h5 class="text-bold">ระบุวันที่และเวลาเข้างาน</h5>

                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label">วันที่เข้างาน</label>
                        <div class="col-sm-4">
                          <input type="date" class="form-control form-control-sm">
                        </div>
                        <div class="col-sm-4">
                          <input type="date" class="form-control form-control-sm">
                        </div>
                      </div>
                      <div class="form-group form-group-sm row mb-0">
                        <label class="col-sm-4 col-form-label">เวลาเข้างาน</label>
                        <div class="col-sm-4">
                          <select class="form-select form-select-sm">
                            <option value="">9:00 AM</option>
                          </select>
                        </div>
                        <div class="col-sm-4">
                          <select class="form-select form-select-sm">
                            <option value="">6:00 PM</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>-->
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  :disabled="loading"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      users: [],
      staff_id: '',
      form: {
        user_id: '',
        type: 'full'
      }
    }
  },
  computed: {
    userImage() {
      let user = this.users.find(u => u.id == this.form.user_id);

      if (user && user.image)
        return user.image;
      else
        return '/images/avatar-girl.png';
    }
  },
  created() {
    this.loadUsers();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-new-staff-modal'));
  },
  methods: {
    resetForm() {
      this.allErrors = null;

      this.form.type = 'full'
      this.form.user_id = ''

      delete this.form.id;

      window.$('#user-select').val('').trigger('change');
    },
    initSelect2() {
      let self = this;

      window.$('#user-select').select2({
        dropdownParent: window.$('#add-new-staff-modal')
      });

      window.$('#user-select').on('change.select2', function () {
        self.form.user_id = window.$(this).val();
      });
    },
    show(staff = null) {
      this.resetForm();

      if (staff) {
        this.title = 'แก้ไขสินค้า';
        this.form = staff;
        this.form.user_id = staff.user.id;

        this.staff_id = staff.id;

        window.$('#user-select').val(staff.user.id).trigger('change');

        delete this.form.id;
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadUsers() {
      this.users = [];

      this.axios.get('/users?status=1')
        .then(res => {
          this.users = res.data.data;

          this.initSelect2();
        })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      let url = '/staffs';

      if (this.staff_id) {
        url = '/staffs/' + this.staff_id
        this.form._method = 'PATCH'
      } else {
        delete this.form._method;
      }

      this.axios.post(url, this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>