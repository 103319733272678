<template>
  <div class="modal fade bs-example-modal-lg"
       id="staff-details-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Staff Detail
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-4">
              <div class="box h-p100">
                <div class="box-header small-box-header">
                  <h4 class="box-title">Basic Detail</h4>
                </div>
                <div class="box-body p-3" v-if="staff">
                  <div class="row">
                    <div class="col-5">
                      <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                           onerror="this.src='/images/avatar-girl.png'"
                           class="w-p100">
                    </div>
                    <div class="col-7">
                      <span style="color:#5156BE;">◆</span>
                      <span>&nbsp;{{ staff.user.full_name }}</span> <br>

                      <span style="color:#5156BE;">◆</span>
                      <span>&nbsp;{{ staff.staff_id }}</span> <br>

                      <span style="color:#5156BE;">◆</span>
                      <span>&nbsp;Phone: {{ staff.user.phone }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <button :class="'btn btn-sm me-2 ' + (currentComponent === 'booked' ? 'btn-primary' : 'btn-secondary')"
                      @click="currentComponent = 'booked'">Booked / Open Slot</button>
              <button :class="'btn btn-sm me-2 ' + (currentComponent === 'past-slots' ? 'btn-primary' : 'btn-secondary')"
                      @click="currentComponent = 'past-slots'">Past Slots</button>
              <button :class="'btn btn-sm me-2 ' + (currentComponent === 'related-cases' ? 'btn-primary' : 'btn-secondary')"
                      @click="currentComponent = 'related-cases'">Related Cases</button>

              <div class="row mt-3">
                <div class="col-12">
                  <component v-if="currentComponent && staff"
                             :staff="staff"
                             :is="currentComponent" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
import Booked from './Booked.vue'
import PastSlots from './PastSlots.vue'
import RelatedCases from './RelatedCases.vue'
export default {
  components: {Booked, PastSlots, RelatedCases},
  data() {
    return {
      modal: null,
      staff: null,
      currentComponent: null,
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('staff-details-modal'));
  },
  methods: {
    show(staffId) {
      this.loadStaff(staffId);
      this.currentComponent = 'booked'
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    loadStaff(staffId) {
      this.staff = null;

      this.axios.get('/staffs/' + staffId)
        .then(res => {
          this.staff = res.data.data;
        })
    }
  }
}
</script>