<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('staff.staff') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('staff.calender') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-body">
          <div class="row">
            <div class="col-md-3">
              <DatePicker v-model="date" />
            </div>
            <div class="col-md-9">
              <div class="box">
                <div class="box-header small-box-header bg-info-light">
                  <h4 class="box-title mb-0">{{ $t('staff.today_staff') }}</h4>
                </div>
                <div class="box-body p-3">
                  <div class="alert alert-secondary"
                       v-if="!todayStaffs.length"
                       role="alert">
                    {{ $t('dashboard.no_items') }}
                  </div>

                  <div class="row" v-else>
                    <div class="col-md-4"
                         v-for="staff in todayStaffs"
                         :key="'staff_'+staff.id">
                      <div class="box mb-2">
                        <div class="box-body p-2">
                          <div class="d-flex align-items-center">
                            <div class="me-15">
                              <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                                   onerror="this.src='/images/avatar-girl.png'"
                                   class="avatar avatar-lg rounded10 bg-primary-light">
                            </div>
                            <div class="d-flex flex-column flex-grow-1 fw-500">
                              <a href="#" class="text-dark hover-primary fs-16">{{ staff.user.full_name }}</a>
                              <span class="text-fade">{{ staff.staff_id }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box">
                <div class="box-header small-box-header">
                  <h4 class="box-title mb-0">{{ $t('staff.daily_staff') }} ({{ formattedDate }})</h4>
                </div>
                <div class="box-body p-3">
                  <div class="alert alert-secondary"
                       v-if="!staffs.length"
                       role="alert">
                    {{ $t('dashboard.no_items') }}
                  </div>

                  <div class="row" v-else>
                    <div class="col-md-4"
                         v-for="staff in staffs"
                         :key="'staff_'+staff.id">
                      <div class="box mb-2">
                        <div class="box-body p-2">
                          <div class="d-flex align-items-center">
                            <div class="me-15">
                              <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                                   onerror="this.src='/images/avatar-girl.png'"
                                   class="avatar avatar-lg rounded10 bg-primary-light">
                            </div>
                            <div class="d-flex flex-column flex-grow-1 fw-500">
                              <a href="#"
                                 @click.prevent="$refs.staffDetailsModal.show(staff.id)"
                                 class="text-dark hover-primary fs-16">{{ staff.user.full_name }}</a>
                              <span class="text-fade">{{ staff.staff_id }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <staff-details-modal ref="staffDetailsModal" />
</template>

<script>
import 'v-calendar/dist/style.css';
import { DatePicker } from 'v-calendar';
import StaffDetailsModal from "@/views/staff/details/StaffDetailsModal.vue";

export default {
  components: {
    DatePicker, StaffDetailsModal
  },
  computed: {
    formattedDate() {
      let d = this.date;
      let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
      let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${ye}-${mo}-${da}`;
    }
  },
  data() {
    return {
      date: new Date(),
      staffs: [],
      todayStaffs: [],
    };
  },
  watch: {
    date() {
      this.loadStaffs()
    }
  },
  created() {
    this.loadStaffs(true);
  },
  methods: {
    loadStaffs(loadToday = false) {
      this.staffs = [];

      this.axios.get('/staffs/calender', {
          params: {
            date: this.formattedDate
          }
        })
        .then(res => {
          if (loadToday)
            this.todayStaffs = res.data.data;

          this.staffs = res.data.data;
        })
    }
  }
}
</script>