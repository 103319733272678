<template>
  <div class="box h-p100">
    <div class="box-header small-box-header">
      <h4 class="box-title">Past Slot</h4>
    </div>
    <div class="box-body p-3 h-500">
      <div id="past-calendar"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['staff'],
  data() {
    return {
      slots: [],
      events: []
    }
  },
  mounted() {
    this.loadSlots();
    /*setTimeout(() => {
      this.initCalender();
    }, 500)*/
  },
  methods: {
    loadSlots() {
      this.axios.get('/staffs/slots/past/' + this.staff.id)
        .then(res => {
          this.slots = res.data.data;
          this.events = res.data.data.map(e => {
            let event = {
              id: e.id,
              start: e.start_time,
              end: e.end_time,
            }

            event.title = e.case.visit.visit_no;
            event.className = 'bg-secondary';

            return event;
          })

          setTimeout(() => {
            this.initCalender();
          }, 500)
        })
    },
    initCalender() {
      let self = this;

      let calendarEl = document.getElementById('past-calendar');
      let calendar = new window.FullCalendar.Calendar(calendarEl, {
        initialView: 'dayGridMonth',
        height: '100%',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek,timeGridDay,listWeek'
        },
        themeSystem: 'bootstrap5',
        events: self.events,
        eventClick: function(info) {
          let slot = self.slots.find(s => s.id == info.event.id);

          window.open('/case/' + slot.case.id + '/notes', '_blank', 'noreferrer');
        }
      });
      calendar.render();
    }
  }
}
</script>