<template>
  <div class="box h-p100">
    <div class="box-body d-flex align-items-center">
      <div class="text-center w-p100">
        <i class="fas fa-user fs-100 text-muted"></i>
        <h4 class="mt-3">ยังไม่ได้ Assign พนักงานเข้าเคสนี้</h4>
        <p class="text-muted m-0">กดที่ปุ่ม “เพิ่มพนักงาน” เพื่อ Assign พนักงานเข้าเคส</p>
      </div>
    </div>
  </div>
</template>