<template>
  <div class="box h-p100">
    <div class="box-header small-box-header">
      <h4 class="box-title">Related Cases</h4>
    </div>
    <div class="box-body p-3">
      <table-component :columns="columns"
                       :rows="cases"
                       :sortable="sortable"
                       @sorting="sorting"
                       :pagination="pagination"
                       @search="search"
                       :search="false"
                       @pageChanged="pageChanged">

        <template v-slot:case_no="{ row }">
          <a href="#" @click.prevent="openInNewTab({name: 'case_notes', params: {id: row.id}})">{{ row.case_no }}</a>
        </template>

        <template v-slot:customer="{ row }">
          <img :src="row.patient.image ? row.patient.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="rounded h-30 w-30">
          {{ row.patient.full_name }}
        </template>

        <template v-slot:doctor_full_name="{ row }">
          <img :src="row.doctor.image ? row.doctor.image : '/images/avatar-girl.png'"
               onerror="this.src='/images/avatar-girl.png'"
               class="rounded h-30 w-30">
          {{ row.doctor.full_name }}
        </template>

        <template v-slot:status="{ row }">
          <case-status :id="row.id"
                       :status="row.status" />
        </template>
      </table-component>
    </div>
  </div>
</template>

<script>
import TableComponent from "@/components/TableComponent";
import CaseStatus from "@/views/case/CaseStatus.vue";
export default {
  props: ['staff'],
  components: {TableComponent, CaseStatus},
  data() {
    return {
      cases: [],
      pagination: null,
      queryParams: {
        staff_id: this.staff.id,
        status: '',
        doctor_id: '',
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: this.$t('case.case_no'),
          field: "case_no",
          sortable: true,
          searchable: true,
          slot: true
        },
        {
          label: this.$t('case.visit_no'),
          field: "visit.visit_no",
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('case.hn'),
          field: "patient.hn",
          sortable: false,
          searchable: true,
          display(row) {
            return row.patient ? row.patient.hn : '';
          }
        },
        {
          label: this.$t('case.name'),
          field: "patient.full_name",
          sortable: false,
          searchable: true,
        },
        {
          label: this.$t('case.doctor'),
          field: "doctor.full_name",
          sortable: true,
          searchable: true,
          slot: true,
        },
        {
          label: this.$t('case.date'),
          field: 'visit.date',
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('case.time'),
          field: 'doctorSlot.time_format',
          sortable: false,
          searchable: false,
        },
        {
          label:  this.$t('case.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
      ],
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.cases = [];

      this.axios.get('/visit-cases', {params: this.queryParams})
        .then((response) => {
          this.cases = response.data.data;
          this.pagination = response.data.meta;
        })
    },
  }
}
</script>