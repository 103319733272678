<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-shift-employee-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Add Employee
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('staff_id')}]">
            <label class="col-sm-3 col-form-label">Staff</label>
            <div class="col-sm-9">
              <select class="form-select form-select-sm"
                      v-model="form.staff_id">
                <option value="">Select Staff</option>
                <option :value="staff.id"
                        v-for="staff in staffs"
                        :key="'staff_'+staff.id">{{ staff.user.full_name }} ({{ staff.staff_id }})</option>
              </select>

              <v-errors :errors="errorFor('staff_id')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      staffs: [],
      form: {
        shift_id: '',
        staff_id: '',
      }
    }
  },
  created() {
    this.loadStaffs();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-shift-employee-modal'));
  },
  methods: {
    show(shiftId) {
      this.form.shift_id = shiftId;
      this.resetForm();
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    resetForm() {
      this.form.staff_id = '';
    },
    loadStaffs() {
      this.staffs = [];

      this.axios.get('/staffs')
        .then(res => {
          this.staffs = res.data.data;
        })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/shifts/add-staff', this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>