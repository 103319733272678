<template>
  <div class="modal fade bs-example-modal-lg"
       id="add-shift-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Add Shift
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('department_id')}]">
            <label class="col-sm-3 col-form-label">Department</label>
            <div class="col-sm-9">
              <select class="form-select form-select-sm"
                      v-model="form.department_id">
                <option value="">Select Department</option>
                <option :value="department.id"
                        v-for="department in departments"
                        :key="'department_'+department.id">{{ department.name }}</option>
              </select>

              <v-errors :errors="errorFor('department_id')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('start')}]">
            <label class="col-sm-3 col-form-label">Start</label>
            <div class="col-sm-9">
              <input type="date"
                     v-model="form.start"
                     class="form-control form-control-sm">

              <v-errors :errors="errorFor('start')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('end')}]">
            <label class="col-sm-3 col-form-label">End</label>
            <div class="col-sm-9">
              <input type="date"
                     v-model="form.end"
                     class="form-control form-control-sm">

              <v-errors :errors="errorFor('end')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  props: ['staffs'],
  data() {
    return {
      modal: null,
      departments: [],
      form: {
        department_id: '',
        start: '',
        end: ''
      }
    }
  },
  created() {
    this.loadDepartments();
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('add-shift-modal'));
  },
  methods: {
    show() {
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    resetForm() {
      this.form.department_id = '';
      this.form.start = '';
      this.form.end = '';
    },
    loadDepartments() {
      this.departments = [];

      this.axios.get('/departments')
        .then(res => {
          this.departments = res.data.data;
        })
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/shifts', this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>