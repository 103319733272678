<template>
  <div class="modal fade bs-example-modal-lg"
       id="select-staff-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Select Staff
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <select class="form-select"
                  v-model="selected_staff_id">
            <option value="">Select Staff</option>
            <option :value="staff.id"
                    v-for="staff in staffs"
                    :key="'staff_'+staff.id">{{ staff.user.full_name }} ({{ staff.staff_id }})</option>
          </select>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  props: ['staffs'],
  data() {
    return {
      modal: null,
      selected_staff_id: ''
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('select-staff-modal'));
  },
  methods: {
    show() {
      this.selected_staff_id = '';
      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    submit() {
      this.hide();
      this.$emit('addStaff', this.selected_staff_id);
    }
  }
}
</script>