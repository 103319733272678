<template>
  <div class="box h-p100">
    <div class="box-header small-box-header">
      <h4 class="box-title">Staff</h4>
    </div>
    <div class="box-body p-3">
      <table class="table table-hover text-nowrap table-small">
        <thead>
        <tr>
          <th>ลำดับ</th>
          <th>รหัสพนักงาน</th>
          <th>ชื่อพนักงาน</th>
          <th class="text-end">การกระทำ</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(staff, i) in staffs.filter(s => selectedStaffs.includes(s.id))"
            :key="'staff_'+staff.id">
          <td>{{ i+1 }}</td>
          <td>{{ staff.user.employee_id }}</td>
          <td>
            <img :src="staff.user.image ? staff.user.image : '/images/avatar-girl.png'"
                 onerror="this.src='/images/avatar-girl.png'"
                 class="rounded h-30 w-30">
            {{ staff.user.full_name }}
          </td>
          <td class="text-end">
            <a href="#"
               class="text-muted"
               @click.prevent="deleteStaff(staff)">
              <i class="fas fa-trash"></i> ลบ
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ['staffs', 'selectedStaffs'],
  methods: {
    deleteStaff(staff) {
      this.$emit('removeStaff', staff.id);
    }
  }
}
</script>