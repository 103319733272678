<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('staff.staff_shift') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('staff.staff_shift') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-7">
              <div class="input-group">
                <input type="text"
                       class="form-control"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ Shift ID. หรือ Updated by">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="filter('department_id', $event.target.value)">
                <option value="">{{ $t('staff.department') }}: {{ $t('staff.all_department') }}</option>
                <option :value="department.id"
                        v-for="department in departments"
                        :key="'department_'+department.id">{{ department.name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData">
                <option value="">{{ $t('staff.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button type="button"
                        @click="$refs.addShiftModal.show()"
                        class="waves-effect waves-light btn btn-primary btn-sm">+ {{ $t('staff.add_shift') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="shifts"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:status="{ row }">
              <shift-status :status="row.status" />
            </template>

            <template v-slot:action="{ row }">
              <router-link :to="{name: 'staff_shift_edit', params: {id: row.id}}"
                           class="text-muted">
                <i class="fas fa-edit"></i> {{ $t('staff.edit') }}
              </router-link> |

              <a href="#"
                 @click.prevent="deleteData(row)"
                 class="text-muted">
                <i class="fas fa-trash"></i> {{ $t('staff.delete') }}
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-shift-modal @added="loadData" ref="addShiftModal" />
</template>

<script>
import AddShiftModal from "@/views/staff/shift/AddShiftModal.vue";
import TableComponent from "@/components/TableComponent";
import ShiftStatus from "./ShiftStatus.vue";

export default {
  components: {AddShiftModal, TableComponent, ShiftStatus},
  data() {
    return {
      departments: [],
      shifts: [],
      pagination: null,
      queryParams: {
        status: '',
        department_id: '',
        page: 1,
        search: '',
        sort: 'created_at',
        sort_order: 'desc',
        per_page: 10
      },
      sortable: {
        order: 'created_at',
        sort: 'desc'
      },
      columns: [
        {
          label: 'Shift ID',
          field: "shift_id",
          sortable: true,
          searchable: true,
        },
        {
          label: 'Department',
          field: 'department.name',
          sortable: true,
          searchable: false,
        },
        {
          label: 'Start Date',
          field: 'start',
          sortable: true,
          searchable: false,
        },
        {
          label: 'End Date',
          field: 'end',
          sortable: true,
          searchable: false,
        },
        {
          label: 'Status',
          field: 'status',
          sortable: true,
          searchable: false,
          slot: true
        },
        {
          label: this.$t('staff.updated_at'),
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('staff.updated_by'),
          field: 'updatedBy.full_name',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('staff.action'),
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
    this.loadDepartments();
  },
  methods: {
    loadDepartments() {
      this.departments = [];

      this.axios.get('/departments')
        .then(res => {
          this.departments = res.data.data;
        })
    },
    loadData() {
      this.shifts = [];

      this.axios.get('/shifts', {params: this.queryParams})
        .then((response) => {
          this.shifts = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    deleteData(row) {
      this.$swal({
        title: 'Are you sure want to delete?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/shifts/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              'Deleted',
              'Shift has been deleted!',
              'success'
            )
          });
        }
      });
    },
  }
}
</script>