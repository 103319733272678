<template>
  <span class="badge badge-warning-light" v-if="status == 0">Draft</span>
  <span class="badge badge-primary-light" v-else-if="status == 1">Live</span>
  <span class="badge badge-success-light" v-else-if="status == 2">Finished</span>
</template>

<script>
export default {
  props: ['status']
}
</script>