<template>
  <div class="content-header">
    <div class="d-flex align-items-center">
      <div class="me-auto">
        <h3 class="page-title">{{ $t('staff.staff') }}</h3>
        <div class="d-inline-block align-items-center">
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><a href="#"><i class="mdi mdi-home-outline"></i></a></li>
              <li class="breadcrumb-item active" aria-current="page">{{ $t('staff.list') }}</li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="box">
        <div class="box-header small-box-header">
          <div class="row">
            <div class="col-md-5">
              <div class="input-group">
                <input type="text"
                       class="form-control"
                       @keyup="searchText($event.target.value)"
                       placeholder="ค้นหาด้วยการระบุ Staff ID หรือ Staff Name หรือ Email">
                <span class="input-group-text"><i class="ti-search"></i></span>
              </div>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="filter('department_id', $event.target.value)">
                <option value="">{{ $t('staff.department') }}: {{ $t('staff.all_department') }}</option>
                <option :value="department.id"
                        v-for="department in departments"
                        :key="'department_'+department.id">{{ department.name }}</option>
              </select>
            </div>
            <div class="col-md-2">
              <select class="form-select"
                      @change="refreshData"
                      v-model="queryParams.status">
                <option value="">{{ $t('staff.all_status') }}</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div class="col-md-2">
              <div class="d-grid">
                <button type="button" class="waves-effect waves-light btn btn-primary btn-sm">
                  <img src="/images/google-calendar.png" style="height: 17px;"> {{ $t('staff.sync_google_calender') }}
                </button>
              </div>
            </div>
            <div class="col-md-1">
              <div class="d-grid">
                <button type="button"
                        @click="$refs.addNewStaffModal.show()"
                        class="waves-effect waves-light btn btn-primary btn-sm">+  {{ $t('staff.new_staff') }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="box-body p-3">
          <table-component :columns="columns"
                           :rows="staffs"
                           @sorting="sorting"
                           :pagination="pagination"
                           @search="search"
                           :search="false"
                           @pageChanged="pageChanged">

            <template v-slot:staff_id="{ row }">
              <a href="" @click.prevent="$refs.staffDetailsModal.show(row.id)">{{ row.staff_id }}</a>
            </template>

            <template v-slot:user_full_name="{ row }">
              <a :class="['avatar',  {'status-success': row.today_has_case},  {'status-secondary': !row.today_has_case}]"
                 href="javascript:void(0)">
                <img :src="row.user.image ? row.user.image : '/images/avatar-girl.png'"
                     onerror="this.src='/images/avatar-girl.png'">
              </a>

              {{ row.user.full_name }}
            </template>

            <template v-slot:action="{ row }">
              <a href="#" class="text-muted"
                 @click.prevent="edit(row)"><i class="fas fa-edit"></i> {{ $t('staff.edit') }}</a> |

              <a href="#"
                 @click.prevent="deleteData(row)"
                 class="text-muted">
                <i class="fas fa-trash"></i> {{ $t('staff.delete') }}
              </a>
            </template>
          </table-component>
        </div>
      </div>
    </div>
  </div>

  <add-new-staff-modal ref="addNewStaffModal" @added="loadData" />
  <staff-details-modal ref="staffDetailsModal" />
</template>

<script>
import AddNewStaffModal from "@/views/staff/modals/AddNewStaffModal";
import StaffDetailsModal from "./details/StaffDetailsModal.vue";
import TableComponent from "@/components/TableComponent";

export default {
  components: {TableComponent, AddNewStaffModal, StaffDetailsModal},
  data() {
    return {
      staffs: [],
      departments: [],
      pagination: null,
      queryParams: {
        status: '',
        department_id: '',
        page: 1,
        search: '',
        sort: 'user.employee_id',
        sort_order: 'asc',
        per_page: 10
      },
      sortable: {
        order: 'user.employee_id',
        sort: 'asc'
      },
      columns: [
        {
          label: this.$t('staff.staff_id'),
          field: "staff_id",
          sortable: true,
          searchable: true,
          slot: true
        },
        {
          label: this.$t('staff.staff_name'),
          field: 'user.full_name',
          sortable: true,
          searchable: true,
          slot: true
        },
        {
          label: this.$t('staff.phone'),
          field: 'user.phone',
          sortable: true,
          searchable: true,
        },
        {
          label:  this.$t('staff.email'),
          field: 'user.email',
          sortable: true,
          searchable: true,
        },
        {
          label: this.$t('staff.department'),
          field: 'department',
          sortable: false,
          searchable: false,
          display(row) {
            return row.user.departments.map(d => d.name).join(', ');
          }
        },
        {
          label: this.$t('staff.status'),
          field: 'status',
          sortable: true,
          searchable: false,
          display(row) {
            if (row.user.status)
              return '<span class="badge badge-success-light">เปิดใช้งาน</span>'
            else
              return '<span class="badge badge-secondary-light">ปิดใช้งาน</span>';
          }
        },
        {
          label: this.$t('staff.updated_at'),
          field: 'updated_at',
          sortable: true,
          searchable: false,
        },
        {
          label: this.$t('staff.updated_by'),
          field: 'updatedBy.full_name',
          sortable: true,
          searchable: true,
          display(row) {
            return row.updatedBy.full_name;
          }
        },
        {
          label: this.$t('staff.action'),
          field: 'action',
          sortable: false,
          slot: true,
          class: ['text-end']
        }
      ],
    }
  },
  created() {
    this.loadData();
    this.loadDepartments();
  },
  methods: {
    loadDepartments() {
      this.departments = [];

      this.axios.get('/departments')
        .then(res => {
          this.departments = res.data.data;
        })
    },
    loadData() {
      this.staffs = [];

      this.axios.get('/staffs', {params: this.queryParams})
        .then((response) => {
          this.staffs = response.data.data;
          this.pagination = response.data.meta;
        })
    },
    edit(row) {
      this.axios.get('/staffs/' + row.id)
        .then(res => {
          let staff = res.data.data;
          this.$refs.addNewStaffModal.show(staff);
        });
    },
    deleteData(row) {
      this.$swal({
        title: this.$t('patients.are_you_sure_want_to_delete'),
        text: this.$t('patients.you_wont_be_able_to_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: this.$t('patients.cancel'),
        confirmButtonText: this.$t('patients.yes_delete_it'),
        confirmButtonColor: '#dd4b39',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios.delete('/staffs/'+row.id).then(() => {
            this.loadData();
            this.$swal.fire(
              this.$t('patients.deleted'),
              this.$t('patients.patient_has_been_deleted'),
              'success'
            )
          });
        }
      });
    },
  }
}
</script>