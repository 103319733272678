<template>
  <div class="modal fade bs-example-modal-lg"
       id="edit-shift-schedule-modal"
       tabindex="-1"
       role="dialog"
       aria-labelledby="myLargeModalLabel"
       aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="myLargeModalLabel">
            Schedule
          </h4>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('status')}]">
            <label class="col-sm-3 col-form-label">Status</label>
            <div class="col-sm-9">
              <select class="form-select form-select-sm"
                      v-model="form.status">
                <option value="">Select Status</option>
                <option value="1">Full Working Day</option>
                <option value="2">Half Working Day</option>
                <option value="3">Annual Leave</option>
              </select>

              <v-errors :errors="errorFor('status')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('start')}]">
            <label class="col-sm-3 col-form-label">Start</label>
            <div class="col-sm-9">
              <input type="time"
                     class="form-control form-control-sm"
                     v-model="form.start">

              <v-errors :errors="errorFor('start')"></v-errors>
            </div>
          </div>
          <div :class="['form-group form-group-sm row mb-0', {'error': errorFor('end')}]">
            <label class="col-sm-3 col-form-label">End</label>
            <div class="col-sm-9">
              <input type="time"
                     class="form-control form-control-sm"
                     v-model="form.end">

              <v-errors :errors="errorFor('end')"></v-errors>
            </div>
          </div>
        </div>
        <div class="modal-footer text-center">
          <button type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal">ยกเลิกการสร้าง</button>

          <button class="btn btn-success"
                  @click="submit">บันทึก</button>
        </div>
      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      form: {
        date: '',
        shift_id: '',
        staff_id: '',
        start: '',
        end: '',
        status: '',
      }
    }
  },
  mounted() {
    this.modal = new window.bootstrap.Modal(document.getElementById('edit-shift-schedule-modal'));
  },
  methods: {
    show(shiftId, date, staffId, schedule) {
      this.resetForm();

      this.form.shift_id = shiftId;
      this.form.date = date;
      this.form.staff_id = staffId;

      if (schedule) {
        this.form.status = schedule.status;
        this.form.start = schedule.start;
        this.form.end = schedule.end;
      }

      this.modal.show();
    },
    hide() {
      this.modal.hide();
    },
    resetForm() {
      this.form.staff_id = '';
      this.form.shift_id = '';
      this.form.start = '';
      this.form.end = '';
      this.form.status = '';
      this.form.date = '';
    },
    submit() {
      this.allErrors = null;
      this.loading = true;

      this.axios.post('/shifts/edit-schedule', this.form)
        .then(() => {
          this.resetForm();
          this.modal.hide();
          this.$emit('added');
        })
        .catch((err) => {
          this.allErrors = err.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
}
</script>